:root {
	--ion-color-primary: #0C344A;
	--ion-color-primary-rgb: 12, 52, 74;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #0b2e41;
	--ion-color-primary-tint: #24485c;

	--ion-color-secondary: #D92050;
	--ion-color-secondary-rgb: 217,32,80;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #bf1c46;
	--ion-color-secondary-tint: #dd3662;

	--ion-color-tertiary: #4ECEC5;
	--ion-color-tertiary-rgb: 78, 206, 197;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #45b5ad;
	--ion-color-tertiary-tint: #60d3cb;

	--ion-color-success: #28BA62;
	--ion-color-success-rgb: 40, 186, 98;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #23a456;
	--ion-color-success-tint: #3ec172;

	--ion-color-warning: #FFCE32;
	--ion-color-warning-rgb: 255, 206, 50;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0b52c;
	--ion-color-warning-tint: #ffd347;

	--ion-color-danger: #c5000f;
	--ion-color-danger-rgb: 197, 0, 15;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #ad000d;
	--ion-color-danger-tint: #cb1a27;

	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 255, 255, 255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 255, 255, 255;
	--ion-color-light-shade: #eeeeee;
	--ion-color-light-tint: #ffffff;


	--ion-color-medium: #5F738D;
	--ion-color-medium-rgb: 95, 115, 141;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #54657c;
	--ion-color-medium-tint: #6f8198;

	--ion-color-dark: #000;
	--ion-color-dark-rgb: 0, 0, 0;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #000;
	--ion-color-dark-tint: #333;

	--ion-color-light-tint: #F9FAFF;
	--ion-color-lightestgrey: #F3F7FF;
}

$dark: #000;
$teal: #4ECEC5;
$main: #052A3F;
$green-600: #28BA62;
$text-primary: #0C344A;
$text-secondary: #5F738D;
$surfacedarker: #F4F6FF;
$lighttint: #F9FAFF;
$error: #D92050;
$red: #E31D1D;
$light: #FFFFFF;
$yellow-light: #FFEFBD;
$lightest-grey: #E8EBF6;
$border-strong: #C4CEDF;
$blue-600: #43BCFF;
$blue-500: #91D7FE;
$bg-message-info: #EAF7FF;
$brand-butter-500: #FFE5D6;
$tint: #D92050;
$lightertint: #F2527B;
$button-disabled: #A6B3C6;
$sentiment-neutral: #4B5158;
$grey: #4e6887;
$lightgrey: #97a6bd;
$lightergrey: #d2d8e2;
$lightestgrey: #F3F7FF;
$sentimentstrong: #0D1846;
$lightestgrey-tint: #e7ecf5;

$butter: #FFF8F4;

$yellow-500: #FFCE32;
$yellow-600: #FFC200;
$yellow-200: #FFEFBD;

$step-600: #666666;

// For Order Status Icons
$orange: #FFC409;
$light-blue-2: #8DC0E8;
$dark-orange: #FF8E09;
$dark-purple: #33006A;

// For Order next steps
$green-500: #2DD36F;
$green-200: #C1F6D6;